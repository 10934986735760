/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'passport-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 6a2 2 0 100 4 2 2 0 000-4"/><path pid="1" d="M2 3.252a1.5 1.5 0 011.232-1.476l8-1.454A1.5 1.5 0 0113 1.797v.47A2 2 0 0114 4v10a2 2 0 01-2 2H4a2 2 0 01-1.51-.688 1.5 1.5 0 01-.49-1.11V3.253zM5 8a3 3 0 106 0 3 3 0 00-6 0m0 4.5a.5.5 0 00.5.5h5a.5.5 0 000-1h-5a.5.5 0 00-.5.5"/>',
    },
});
